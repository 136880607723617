@import url(./assets/fonts/SuisseIntl-Book/font.css);

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  position: absolute;
  left: 0;
  right: 0;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.mui-image-wrapper.icon-tidal{
  max-width: 20px;
}

.MuiTypography-subtitle2 .icon-tidal{
  max-width: 14px;
  opacity:.6;
}

